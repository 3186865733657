import { t } from "i18next"
import { observer } from "mobx-react-lite"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"
import { memo } from "react"
import { useStore } from "../../contexts/store"
import { Message as MessageType } from "../../../../types/Message"
import EditMenu from "./EditMenu"
import { Avatar, Box, Button, IconButton } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import { UserInfo } from "../../../../types/User"
import { ScrollSeekPlaceholderProps } from "react-virtuoso"
import UserInfoAvatar from "./UserInfoAvatar"
import { PodClass } from "../../classes/Pod"
import TimeFromNow from "./TimeFromNow"
import ViewTracker from "./ViewTracker"
import Reactions from "./Reactions"
import NewMessage from "./NewMessage"

// message from thread
const Message = ({message, interactionId}: {message: MessageType, interactionId: string}) => {
  const { t } = useTranslation()
  const { podStore, uiStore } = useStore()
  const isEdited = (uiStore.editedMessageId === message.messageId) ? true : false
  const pod:PodClass|null = podStore.pod
  if (!message || !pod || !interactionId) return null
  // get the interaction which is connected to the message thread
  const interaction   = pod.getInteraction(interactionId)
  if (!interaction) return <div>Could not find interaction</div>
  if(interaction.interactionId === message.messageId) {
    console.warn('an attempt was made to present a comment as a message', interaction)
    return null
  }

  const podId = pod.podId
  const pdfId = interaction.anchor.nodeId
  const interactionType = "message"

  const userInfo = pod.userInfos[message.userId]
  const userId = userInfo ? userInfo.userId : null
  const userName = userInfo ? userInfo.userName : t('unknown')

  const onHoverMessage = () => {
    // show emoji picker elment
    const emojiPickerElement = document.getElementById(`emoji-picker-${message.messageId}`)
    if(emojiPickerElement) emojiPickerElement.classList.remove("hidden")
  }

  const onHoverOutMessage = () => {
    // hide emoji picker elment
    const emojiPickerElement = document.getElementById(`emoji-picker-${message.messageId}`)
    if(emojiPickerElement) emojiPickerElement.classList.add("hidden")
  }

  return (
    <ViewTracker type={interactionType} id={message.messageId}>
    <Box onMouseOver={onHoverMessage} onMouseOut={onHoverOutMessage}
        sx={{
          backgroundColor: isEdited ? "#edf2fc" : "",
          display: "grid",
          gridTemplateColumns: "max-content auto",
          opacity: isEdited ? 0.3 : 1,
          padding: "10px 20px"
        }}
    >
      {/* avatar (left) */}
      <Box sx={{marginRight:'15px',justifySelf: "right"}}>
        <UserInfoAvatar sx={{height:36, width:36}} userId={userId} podId={podId} />
      </Box>
      {/* content (right) */}
      <Box sx={{display: "grid", gridTemplateRows: "max-content auto min-content"}}>
        {/* header */}
        <Box sx={{display: "grid", gridTemplateColumns: "max-content auto min-content", alignItems: "center", marginBottom: "3px", marginLeft: "5px"}}>
          <Box sx={{fontWeight: "600", color: "#4c4c57", wordBreak: "break-word"}}>
            {userName}
          </Box>
          <Box sx={{marginLeft: "15px", color: "#808080a6", fontSize: "13px"}}>
            <TimeFromNow timestamp={message.tModified ? message.tModified*1000 : 0} />
          </Box>
          <EditMenu
            interactionId={message.messageId}
            interactionType={interactionType}
            pdfId={pdfId}
            podId={podId}
            messageId={message.messageId}
            threadId={message.threadId}
          />
        </Box>
        <Box sx={{marginLeft: "5px"}}>
          {/* message */}
          <Box sx={{opacity: isEdited ? 0.5 : 1, paddingRight: "30px", wordBreak: "break-word", whiteSpace: "pre-line"}}>
            {message.text}
            {message.tCreated !== message.tModified &&
              <Box sx={{
                alignItems: "center",
                color: "#b6b6b6",
                display: "grid",
                fontSize: "11px",
                fontStyle: "italic",
                gridTemplateColumns: "max-content max-content",
                justifyContent: "end",
                padding: "2px 10px 0 0"
              }}>
                <EditIcon sx={{fontSize: "15px"}} /> {t('Edited')}
              </Box>
            }
          </Box>
        </Box>
        <Reactions interactionId={message.messageId} interactionType={interactionType} podId={podId}  reactions={message.reactions} />
      </Box>
    </Box>
    </ViewTracker>
  )
}


export default observer(Message)


// placeholder for message when scrolling
export const ScrollSeekPlaceholder =  memo(({height, index, context: {hideUserMessage, lastMessageViewed, messages, podId}}: ScrollSeekPlaceholderProps& { context: {hideUserMessage?: boolean, lastMessageViewed: {index: number|null, messageId: string|null} , messages: MessageType[], podId: string} }) => {
  const { podStore, sessionStore, uiStore } = useStore()
  const message = messages[index]
  const session = sessionStore.session
  const showEditIcon = message.userId === session.user.userId
  const timestamp = message.tModified ? message.tModified*1000 : 0
  // get user informations
  const userInfo: UserInfo | null = (message.userId) ? podStore.getUserInfo(message.userId, podId) : null
  const userName = userInfo ? userInfo.userName : t('unknown')
  const isEdited = (uiStore.editedMessageId === message.messageId) ? true : false

  // hide user message
  if(hideUserMessage && message.userId === sessionStore.session.user.userId) {
    return <Box sx={{height: "1px"}} />
  }

  return (
    <Box sx={{
      backgroundColor: isEdited ? "#edf2fc" : "",
      boxSizing: "border-box",
      height: height-0.5,
      opacity: isEdited ? 0.3 : 1,
      overflow: "hidden",
    }}>
      {(lastMessageViewed.messageId && lastMessageViewed.messageId === message.messageId) &&
        <NewMessage key={`${message.messageId} new message`} />
      }
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "max-content auto",
          padding: "10px 20px"
        }}
      >
        {/* avatar (left) */}
        <Box sx={{marginRight:'15px',justifySelf: "right"}}>
          <UserInfoAvatar sx={{height:36, width:36}} userId={message.userId} podId={podId} userInfoObject={userInfo} />
        </Box>
        {/* content (right) */}
        <Box sx={{display: "grid", gridTemplateRows: "max-content auto min-content"}}>
          {/* header */}
          <Box sx={{display: "grid", gridTemplateColumns: "max-content auto min-content", alignItems: "center", marginBottom: "3px", marginLeft: "5px"}}>
            <Box sx={{fontWeight: "600", color: "#4c4c57", wordBreak: "break-word"}}>
              {userName}
            </Box>
            <Box sx={{marginLeft: "15px", color: "#808080a6", fontSize: "13px"}}>
              {dayjs(timestamp).fromNow()}
            </Box>
            {showEditIcon &&
              <IconButton id="placeholde-edit-menu">
                <MoreVertIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
              </IconButton>
            }
          </Box>
          <Box sx={{marginLeft: "5px"}}>
              <Box sx={{opacity: isEdited ? 0.5 : 1, paddingRight: "30px", wordBreak: "break-word", whiteSpace: "pre-line"}}>
                {message.text}
                {message.tCreated !== message.tModified &&
                  <Box sx={{
                    alignItems: "center",
                    color: "#b6b6b6",
                    display: "grid",
                    fontSize: "11px",
                    fontStyle: "italic",
                    gridTemplateColumns: "max-content max-content",
                    justifyContent: "end",
                    padding: "2px 10px 0 0"
                  }}>
                    <EditIcon sx={{fontSize: "15px"}} /> {t('Edited')}
                  </Box>
                }
              </Box>
          </Box>
          {/* reactions */}
          <Box sx={{
            display: "flex",
            flexWrap: "wrap",
            gridGap: "5px",
            justifyItems: "center",
            marginTop: "5px",
            minHeight: "33,5px",
            paddingRight: "30px"
          }}>
            {Object.entries(message.reactions).map(([unicode, users]) => {
              if(users.length === 0) return null
              const pickedByMyself = users.includes(session.user.userId)
              return (
                <Button key={unicode} sx={{
                  backgroundColor: pickedByMyself ? "#edf2fc" : "whitesmoke",
                  border: pickedByMyself ? "1px solid #1e5ad9" : "1px solid whitesmoke",
                  color: pickedByMyself ? "#1e5ad9" : "grey",
                  display: "grid",
                  fontSize: "18px",
                  gridTemplateColumns: "auto min-content",
                  gridGap: "3px",
                  height: "min-content",
                  minWidth: "50px",
                  padding: "0 2px"
                }}>
                  {unicode}
                  <Box sx={{fontSize: "13px"}}>
                    {users.length}
                  </Box>
                </Button>
              )
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  )
})
