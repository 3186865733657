import React, { useState, memo, CSSProperties, useEffect, useMemo } from 'react'
import { useParams } from '../../helper/Helper'
// components
import Error from '../LoadError'
// pdf
import { Document, Page, pdfjs } from 'react-pdf'
import { PDFDocumentProxy } from 'pdfjs-dist'
import 'react-pdf/dist/esm/Page/TextLayer.css'
// virtualization
import { VariableSizeList, FixedSizeList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
// mui
import { useStore } from '../../contexts/store'
import { observer } from 'mobx-react-lite'
import Overlay from './Overlay'
import MarginaliaOverlay from './MarginaliaOverlay'

import LoadingPdf from './LoadingPdf'
import PageClickEvents from './PageClickEvents'
import PageMargin from './PageMargin'

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js'


const Pdf = ({
    pageListRef,
    pdfId,
    pdfUrl,
    podId,
    interactionEditId,
    storeId,
    thumbListRef,
    thumbnailUrl
  }: {
    pageListRef: any,
    pdfId: string,
    pdfUrl: string,
    podId: string,
    interactionEditId?: string,
    storeId: string,
    thumbListRef?: any,
    thumbnailUrl: string
  }) => {
  const params = useParams()
  const { pdfMetaStore, uiStore, podStore } = useStore()
  const [loadingError, setLoadingError] = useState(false)
  const [hasVariableSize, setHasVariableSize] = useState<boolean | null>(null)
  const [pageHeights, setPageHeights] = useState<number[]>([])
  const [pageWidths, setPageWidths] = useState<number[]>([])
  const [numPages, setNumPages] = useState(0)
  const scale = pdfMetaStore.getScale(storeId)
  const [currentScale, setCurrentScale] = useState(scale)
  const pod = podStore.pod

  // counter that ensures that a rerender can be triggered outside the component
  // eslint-disable-next-line
  const _forceUpdate = uiStore.forceUpdate

  useEffect(() => {
    // update the viewport of the pdf based on the new scaling
    function handleScaleChange (prevScale: number, nextScale: number) {
      // check if scaleSelect is a number
      if(isNaN(nextScale) === false) {
        const heights = pageHeights.map(height => (height/prevScale)*nextScale)
        const widths = pageWidths.map(width => (width/prevScale)*nextScale)
        // update component state
        setPageHeights(heights)
        setPageWidths(widths)
        setCurrentScale(nextScale)
        // update store
        pdfMetaStore.setPageHeights(storeId, heights)
        pdfMetaStore.setPageWidths(storeId, widths)
      }
    }
    // recalculate page sizes each time the scaling changes
    if(scale && scale !== currentScale) handleScaleChange(currentScale, scale)
  });

  useEffect(() => {
    // on mount: initialize pdfMeta in store
    if(pdfMetaStore.isInitialized(storeId) === false) {
      pdfMetaStore.initPdfMeta(storeId, pdfUrl, thumbnailUrl)
    }
    // on unmount: reset loading status and reset edited message
    return () => {
      pdfMetaStore.setLoaded(storeId, false)
      pdfMetaStore.setHasVariableSize(storeId, null)
      uiStore.setEditedMessageId(null)
      // stop view tracker on unmount
      const pagePos = pdfMetaStore.getPagePos(storeId)
      if(pagePos) {
        const activePage = Math.round(pagePos[0]) + 1
        podStore.setView(false, 'pdfPage', pdfId, activePage)
      }
    }
  }, [pdfMetaStore, uiStore, storeId, pdfUrl, thumbnailUrl, pdfId, podStore])

  if (!pod) return null

  // set the parameters of the pdf after loading and calculate viewport
  const onDocumentLoadSuccess = (pdf: any) => {
    if(pdf && pdf.numPages) {
      calculateViewport(pdf)
    }
  }

  // save the width and height of each pdf page and test if all pages are the same size
  const calculateViewport = async (pdf: PDFDocumentProxy) => {
    if(pdf && scale) {
      let heights: number[] = []
      let widths: number[] = []
      // get height and width from each page
      for(let i = 1; i <= pdf.numPages; i++) {
        const page = await pdf.getPage(i)
        let viewport = page.getViewport({scale: scale})
        // compare each page with the first to see if all pages are the same size
        if(Array.isArray(heights) && heights.length && heights[0] !== viewport.height) {
          setHasVariableSize(true)
          pdfMetaStore.setHasVariableSize(storeId, true)
        }
        else {
          setHasVariableSize(false)
          pdfMetaStore.setHasVariableSize(storeId, false)
        }
        heights.push(viewport.height)
        widths.push(viewport.width)
      }
      // update component state
      // prevent unnecessary rendering by setting all component variables at the same time
      setNumPages(pdf.numPages)
      setPageHeights(heights)
      setPageWidths(widths)
      // update store
      pdfMetaStore.setNumPages(storeId, pdf.numPages)
      pdfMetaStore.setPageHeights(storeId, heights)
      pdfMetaStore.setPageWidths(storeId, widths)
      // save inital page widths and heights for thumbnails
      pdfMetaStore.setStaticPageHeights(storeId, heights)
      pdfMetaStore.setStaticPageWidths(storeId, widths)
      pdfMetaStore.setLoaded(storeId, true)
    }
  }

  const getPageHeight = (pageIndex: number) => {
    if(Array.isArray(pageHeights) && pageHeights.length) return pageHeights[pageIndex]
    else {
      console.error("empty height array")
      return 0
    }
  }

  /** loads the pdf page selected by react-window:
   * when scrolling or loading, an image of the page is displayed to increase performance
   * if the page has not been loaded yet, show only one image without loading other pages in the background,
   * so that not too many elements have to be loaded at once when scrolling fast.
   * memo: prevent unnecessary re-rendering of pages when properties of the parent component are changed
   */
  const Segment = memo( ({ index, isScrolling, style }: {index: number, isScrolling: boolean, style: CSSProperties}) => {
    // prevent elements from loading before the page count of the pdf has been loaded
    if(pdfMetaStore.getLoaded(storeId) === false) return null
    // reference to pdf page, if page has not been created yet it is null
    const page = document.getElementById(`${storeId}-page-${index}`)
    const pageHeight = pageHeights[index]
    const pageMargin = uiStore.pageMargin ? uiStore.pageMargin*scale: 0

    const pageStyle = {
      backgroundColor: "whitesmoke",
      display: "grid",
      gridTemplateColumns: "max-content max-content max-content",
      height: pageHeight+30,
      justifyContent: "center",
      padding: "15px 0",
      // prevent the page margins from being cut off when zooming in
      minWidth: pageWidths[index] + 2*pageMargin
    }

    var marginLeft  = (pod.content.pdfFiles[pdfId].pages[index+1].mLeft - 50) * scale
    var marginRight = (pod.content.pdfFiles[pdfId].pages[index+1].mRight) * scale

    if(isScrolling && page === null) {
      return (
        <div style={{...style, ...pageStyle}}>
            <PageMargin
              height={pageHeight}
              interactionEditId={interactionEditId}
              scale={scale}
              transformOrigin='top right'
              position='left'
              margin={null}
            />
            <img
              alt={`pdf page ${index+1}`}
              height={pageHeight}
              src={thumbnailUrl+"/"+(index+1)}
              width={pageWidths?.length ? pageWidths[index] : "100%"}
            />
            <PageMargin
              height={pageHeight}
              interactionEditId={interactionEditId}
              scale={scale}
              transformOrigin='top left'
              position='right'
              margin={null}
            />
        </div>
      )
    }
    else return (
      <>
        <div id={`${storeId}-preview-image-${index}`} style={{...style, ...pageStyle}}>
            <PageMargin
              height={pageHeight}
              interactionEditId={interactionEditId}
              scale={scale}
              transformOrigin='top right'
              position='left'
              margin={marginLeft}
            />
            <img
              alt={`pdf page ${index+1}`}
              height={pageHeight}
              src={thumbnailUrl+"/"+(index+1)}
              width={pageWidths?.length ? pageWidths[index] : "100%"}
            />
            <PageMargin
              height={pageHeight}
              interactionEditId={interactionEditId}
              scale={scale}
              transformOrigin='top left'
              position='right'
              margin={marginRight}
            />
        </div>
        <div id={`${storeId}-page-${index}`} className='hidden' style={{...style, ...pageStyle}}>
            <PageMargin
              height={pageHeight}
              interactionEditId={interactionEditId}
              scale={scale}
              transformOrigin='top right'
              position='left'
              margin={marginLeft}
            />
            <Page
              onRenderSuccess={() => pageRendered(index)}
              pageNumber={index+1}
              scale={scale}
              renderAnnotationLayer={false}
            />
            <PageMargin
              height={pageHeight}
              interactionEditId={interactionEditId}
              scale={scale}
              transformOrigin='top left'
              position='right'
              margin={marginRight}
            />
            <MarginaliaOverlay
              childrenDepth={0}
              interactionEditId={interactionEditId}
              interactionsShown={["annotation"]}
              pageId={`${storeId}-page-${index}`}
              podId={podId}
              nodeId={pdfId}
              pageNumber={index+1}
            />
            <Overlay
              childrenDepth={1}
              interactionEditId={interactionEditId}
              pageId={`${storeId}-page-${index}`}
              pdfId={pdfId}
              pageNumber={index+1}
              scale={scale}
            />
            <MarginaliaOverlay
              childrenDepth={2}
              interactionEditId={interactionEditId}
              interactionsShown={["comment", "emotion", "link", "tagging", "weblink", "readingQuestion"]}
              pageId={`${storeId}-page-${index}`}
              podId={podId}
              nodeId={pdfId}
              pageNumber={index+1}
            />
            <PageClickEvents
              currentScale={currentScale}
              interactionEditId={interactionEditId}
              pageNumber={index}
              pdfId={pdfId}
              storeId={storeId}
            />
        </div>
      </>
    )
  })

  const pageRendered = (index: number) => {
    const page = document.getElementById(`${storeId}-page-${index}`)
    const previewImage = document.getElementById(`${storeId}-preview-image-${index}`)
    if(page && previewImage) {
      page.classList.remove("hidden")
      previewImage.classList.add("hidden")
    }
  }

  const updatePagePosition = () => {
      const pagePos = calculatePagePosition()
      if(pagePos) {
        // handle view tracker, check if page changed
        const prevPagePos = pdfMetaStore.getPagePos(storeId)
        if(prevPagePos) {
          const prevActivePage = Math.round(prevPagePos[0]) + 1
          const activePage = Math.round(pagePos[0]) + 1
          // page has changed
          if(prevActivePage !== activePage) {
            // fix that last page will not be recognized in view when zoom level is low
            recognizeLastPageView(prevActivePage, activePage)
            podStore.setView(true, 'pdfPage', pdfId, activePage)
            podStore.setView(false, 'pdfPage', pdfId, prevActivePage)
          }
        }
        // update scroll position from page
        pdfMetaStore.setPagePos(storeId, pagePos)
      }
  }

  const recognizeLastPageView = (prevActivePage: number, activePage: number) => {
    if(pageListRef && pageListRef.current && pageListRef.current.props){
      // get height of pdf view window
      const listHeight = pageListRef.current.props.height
      const prevPageHeight =  Math.round(pageHeights[prevActivePage-1])
      const currentPageHeight =  Math.round(pageHeights[activePage-1])
      // which page will follow (up or down)
      const nextPage = (prevActivePage < activePage) ? activePage+1 : activePage-1
      const nextPageHeight =  Math.round(pageHeights[nextPage-1])
      // next page is the last page
      if(nextPage === pageHeights.length) {
        // will the current page be scrollable past the halfway point
        // to trigger a page break event for the next page
        // also consider padding of the next page
        if((listHeight-nextPageHeight-30) > currentPageHeight/2) {
          podStore.setView(true, 'pdfPage', pdfId, nextPage)
        }
      }
      // remove view from last page if it was set before
      // check for it on page break for second last page on scrolling up
      // (page break for last page could not be reached before)
      if(prevActivePage === (pageHeights.length-1) && (activePage < prevActivePage)) {
        const lastPageHeight = Math.round(pageHeights[pageHeights.length-1])
        if((listHeight-lastPageHeight-30) > prevPageHeight/2) {
          podStore.setView(false, 'pdfPage', pdfId, pageHeights.length)
        }
      }

    }
  }

   /* pagePos format:
   *  [page number of the upper edge of the page, page number of the lower edge of the side, scrollOffset from top]
   */
  const calculatePagePosition = () => {
    if(pageListRef && pageListRef.current && pageListRef.current.state && pageListRef.current.props){
      // get the current scroll offset from the list of pdf pages
      const scrollOffset = pageListRef.current.state.scrollOffset
      // get height of pdf view window
      const listHeight = pageListRef.current.props.height
      // upper edge of the field of view
      let pagePosY0 = 0
      // lower edge of the field of view
      let pagePosY1 = 0

      // if not all pdf pages have the same size, the scroll distance must be subtracted page by page
      // the position within the corresponding page can be calculated from the remaining height
      var pageOffset = scrollOffset
      if(hasVariableSize) {
        let num = 0
        for(const height of pageHeights) {
          pageOffset -= height
          if(pageOffset < 0) {
            const rest = 1-(Math.abs(pageOffset)/height)
            pagePosY0 = num+rest
            break
          }
          num++
        }
        if(pageHeights.length >= num)
        pagePosY1 = pagePosY0 + listHeight/pageHeights[num]
      }
      else {
        const pageHeight = pageHeights[0]
        pagePosY0 = scrollOffset/pageHeight
        pagePosY1 = pagePosY0 + listHeight/pageHeight
      }
      // round to two decimal places
      pagePosY0 = Math.round(pagePosY0*100)/100
      pagePosY1 = Math.round(pagePosY1*100)/100

      return [pagePosY0, pagePosY1, scrollOffset]
    }
  }

  // each time the pdf change: update reference and handle scrolling
  const handleRefChange = (ref: any) => {
    pageListRef.current = ref
    const pagePos = pdfMetaStore.getPagePos(storeId)
    const interactionId = params.interactionId

    // scroll to interaction inside edit modal
    if(interactionEditId) {
      scrollToInteraction(storeId, pdfId, interactionEditId, ref)
    }
    // follow link: scroll to other side
    else if(interactionId) {
      scrollToInteraction(storeId, pdfId, interactionId, ref)
    }
    else if(ref && pagePos && pagePos.length) {
      let pagePosTop = pagePos[0]
      if(pagePos) {
        // pdf with different page sizes support integer numbers only
        if(hasVariableSize) ref.scrollToItem(Math.floor(pagePosTop), "start")
        else ref.scrollToItem(pagePosTop, "start")
        // scroll thumbnail list to acitve page
        if(thumbListRef && thumbListRef.current)
        thumbListRef.current.scrollToItem(Math.round(pagePosTop), "start")
        // start view tracker on first pdf load
        const activePage = Math.round(pagePos[0]) + 1
        podStore.setView(true, 'pdfPage', pdfId, activePage)
      }
    }
  }

  const scrollToInteraction = (storeId: string, pdfId: string, interactionId: string, ref: any) => {
    if(ref && storeId && pdfId && interactionId) {
      const pageHeights = pdfMetaStore.getPageHeights(storeId)
      const scale = pdfMetaStore.getScale(storeId)
      const hasVariableSize = pdfMetaStore.getHasVariableSize(storeId)

      let anchor = null
      if(interactionEditId && interactionEditId !== "addLink") {
        const interactionEditAnchor = podStore.getInteractionEditAnchor(interactionEditId)
        if(interactionEditAnchor) anchor = interactionEditAnchor
      }
      if(interactionEditId === "addLink") {
        // fix bug: always update page pos
        const pagePos = calculatePagePosition()
        if(pagePos && pagePos.length === 3) {
          pdfMetaStore.setPagePos(storeId, pagePos)
        }
      }
      // if interaction is not edited or reset in edited selection text
      if(!anchor && interactionId !== "addLink") {
        const interaction = pod.getInteraction(interactionId)
        if(interaction) anchor = interaction.anchor
      }

      // get page and position from the first selected rectangle of the interaction
      if(hasVariableSize !== null && pageHeights?.length && scale && anchor && anchor.rects.length) {
        const interactionPage = anchor.rects[0].p -1
        const interactionY = anchor.rects[0].y*scale
        const pageHeight = interactionPage >= 0 ? pageHeights[interactionPage] : null
        // calculate page position
        if(pageHeight) {
          let pos = interactionY/pageHeight + interactionPage
          // pdf with different page sizes support integer numbers only
          if(hasVariableSize) {
            pos = Math.floor(pos)
          } else {
            // create distance from top margin
            pos = pos - 0.2
          }
          if(pos < 0) {
            pos = 0
            // fix bug: if there is no scrolling,
            // the values in the store are not updated, in this case update them manually
            const pagePos = calculatePagePosition()
            if(pagePos && pagePos.length === 3) {
              const scrollPos = pagePos[2]
              if(scrollPos === pos) pdfMetaStore.setPagePos(storeId, pagePos)
            }
          }
          ref.scrollToItem(pos, "smart")
        }
      }
    }
  }

  const SamePageSize = (props: any) => (
    <AutoSizer>
      {({ height, width }:{ height:number, width:number }) => (
        <FixedSizeList
          height={height}
          itemCount={numPages}
          itemSize={Array.isArray(pageHeights) ? pageHeights[0] : 0}
          onScroll={updatePagePosition}
          overscanCount={2}
          ref={handleRefChange}
          useIsScrolling {...props}
          width={width}
        >
          {Segment}
        </FixedSizeList>
      )}
    </AutoSizer>
  )

  const VariablePageSize = (props: any) => (
    <AutoSizer>
      {({ height, width }:{ height:number, width:number }) => (
        <VariableSizeList
          estimatedItemSize={height}
          height={height}
          itemCount={numPages}
          itemSize={getPageHeight}
          onScroll={updatePagePosition}
          overscanCount={2}
          ref={handleRefChange}
          useIsScrolling {...props}
          width={width}
        >
          {Segment}
        </VariableSizeList>
      )}
    </AutoSizer>
  )

  const SegmentContainer = () => {
    if(hasVariableSize) return <VariablePageSize />
    else return <SamePageSize />
  }

  // if pdf could not be loaded: show error dialog
  if(loadingError) return <Error />
  return (
    <div id={`${storeId}-pdf-viewer`} style={{display: "grid"}}>
      <Document
          className={"whiteSmoke"}
          file={pdfUrl}
          loading={<LoadingPdf />}
          onLoadError={(error) => {
            setLoadingError(true)
            console.warn("onLoadError:",error)
          }}
          // onLoadProgress={ (e) => { console.log('documentload', e);  } }
          onLoadSuccess={onDocumentLoadSuccess}
          onSourceError={(error) => {
            setLoadingError(true)
            console.log('Error while retrieving document source!', error.message)
          }}
          options={DocumentOptions()}
          renderMode={'canvas'}
          >
            {pageHeights?.length && (
                <SegmentContainer />
            )}
      </Document>
    </div>
  )
}

export default observer(Pdf)

// define react-pdf options outside of React component with useMemo to prevent reloading
const DocumentOptions = () => {
  const { sessionStore } = useStore()
  const sessionId = sessionStore.session.sessionId
  const documentOptions = useMemo(() => {
    const options = {
      cMapPacked: true,
      httpHeaders: {"X-SHRIMP-ID": sessionId}
    }
    return options
  }, [sessionId])
  return documentOptions
}
