export const expressSessionIdMaxLength = 128;
export const oidcIssMaxLength = 100;
export const oidcSubMaxLength = 49; // ! implicit own db limit @todo: oidc standard allows up to 255 characters: https://openid.net/specs/openid-connect-core-1_0.html#IDToken

export const satosaNameIdMaxLength = 256; // saml standard allows up to 256 characters: 8.3.7 Persistent Identifier http://docs.oasis-open.org/security/saml/v2.0/saml-core-2.0-os.pdf
export const satosaEntityIdMaxLength = 1024;

export const uuidMaxLength = 36;
export const usernameMaxLength = 36;
export const instantLoginMaxLength = 36;
export const instantUserPwMinLength = 8;
export const instantUserPwMaxLength = 18;
export const userPwMinLength = 8;
export const userPwMaxLength = 18;

export const podNameMaxLength = 128;
export const podKeyphraseMinLength = 4;
export const podKeyphraseMaxLength = 36;
export const podDescriptionMaxLength = 255;
export const podIdHrMaxLength = 36;

export const tagNameMaxLength = 64;
export const tagDescriptionMaxLength = 255;

export const folderNameMaxLength = 128;
export const folderDescriptionMaxLength = 255;

export const threadNameMaxLength = 32;

// L + 2 bytes prefix, where L < 2^16, L represents the actual length in bytes of a given string value; utf-8 === n.o. single-byte chars
// effective Length is less due to multi-byte characters
export const mariaDbTextMaxLength = (2**16) - 1;

export const mariaDbLongTextMaxLength = (2**32) - 1;
export const mariaDbVarChar36Length = 36;

export const defaultMaxLength = mariaDbTextMaxLength; // other value?
export const opDataMaxLength = mariaDbTextMaxLength;

export const interactionIdMaxLength = mariaDbVarChar36Length;
export const podIdMaxLength = mariaDbVarChar36Length;
export const anchorNodeIdMaxLength = mariaDbVarChar36Length;

export const anchorRectsMaxLength = defaultMaxLength;
export const anchorRelTextMaxLength = defaultMaxLength; // specific value in the future?
export const interactionLabelMaxLength = defaultMaxLength; // specific value in the future?
export const msgTextMaxLength = defaultMaxLength; // specific value in the future?
export const pdfPageFulltextMaxLength = defaultMaxLength; // specific value in the future?

export const fingerprintDigestMaxLength = Math.floor(mariaDbLongTextMaxLength / 2); // 2 fingerprints are possibly combined into pwaErrorReport with max length:

export const pwaErrorReportMaxLength = mariaDbLongTextMaxLength;
export const appVersionMaxLength = 36
export const pdfFileNameMaxLength = 48;
export const pdfFileDescriptionMaxLength = 32;
export const pdfFileHashMaxLength = 32;

export const notUserNameRegex = /(\r\n|\r|\n)|[^ \P{C}\P{Z}]+|[^\*:,-\._\P{P}]+/ug;
export const notPodIdHrRegex = /(\r\n|\r|\n)|[ \/]+/ug;

//@todo: do not allow capital letters of ascii set
export const notInstantLoginRegex  = /[^a-zA-Z0-9- _,\.]+/ug; // was: /(\r\n|\r|\n)|[^ \P{C}\P{Z}]+|[^,-\._\P{P}]+/ug; // a.k.a. 'login'
export const notInstantUserPwRegex = /[^a-zA-Z0-9\-]/ug;

export const notUuidRegex = /[^a-zA-Z0-9_\+\.\-]/ug;
export const notTagNameRegex = /[\p{C}\p{Z}]*[^-._\P{P}]*/gu;

export const notExpressSessionIdRegex = /[^a-zA-Z0-9_\-]/ug;

export const utf8EmojiRegex = /\p{Extended_Pictographic}/ug;
export const NoUtf8EmojiRegex = /[\P{Extended_Pictographic}]/ug; // this matches ZWJ as well, which may be part of parsed Emojis

export const notAsciiRegex = /\P{ASCII}+/ug;

export const colorCodeRegex = /#[a-fA-F0-9]{6}/;
export const colorCodeRegexShort = /#[a-fA-F0-9]{3}/;

export const podAssetTypes = ['avatar', 'podImage', 'folderImage']
export const notMimeTypeRegex = /[^a-zA-Z\/]/