import { ChangeEvent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"
import { useParams } from "../../../helper/Helper"
import { useStore } from "../../../contexts/store"
import { iWeblink, interactionAnchor } from "../../../../../types/Interaction"
import { Op_addWeblink, Op_deleteAnnotation, Op_editWeblink } from "../../../../../types/Ops"
import SaveCancel from "../../Elements/SaveCancel"
import { Alert, Box, TextField } from "@mui/material"

const InteractionModalWeblink  = ({
    anchor,
    interaction
  }:
  {
    anchor: interactionAnchor | null,
    interaction: iWeblink
  }) => {
  const { t } = useTranslation()
  const [label, setLabel] = useState<string>("")
  const [url, setUrl] = useState<string>("")
  const [isValidUrl, setIsValidUrl] = useState(true)
  const { podId, pdfId } = useParams()
  const { sessionStore, podStore, opStore, uiStore } = useStore()
  const { session } = sessionStore
  const isTransformation = (interaction.interactionType === "annotation") ? true : false

  useEffect(() => {
    // if weblink is edited: load existing weblink data
    if(interaction && !isTransformation) {
      setLabel(interaction.label)
      setUrl(interaction.url)
    }
  }, [podStore, interaction, pdfId, isTransformation])

  if(anchor === null) return null

  function validateUrl(inputUrl: string) {
    const notUrlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/ug
    if(inputUrl.match(notUrlRegex)) {
      return inputUrl
    } else {
      setIsValidUrl(false)
      return null
    }
  }

  const handleSave = () => {
    const validatedUrl = validateUrl(url)
    if(validatedUrl && validatedUrl === url) {
      const editAnchor = podStore.getInteractionEditAnchor(interaction.interactionId)
      // distinguish whether weblink is edited or newly created
      if(!isTransformation) {
        // save only if something changes in the input or relText was edited
        if(validatedUrl !== interaction.url || label !== interaction.label || editAnchor) {
          opStore.doOp({
            op: "editWeblink",
            podId: podId,
            data: {
              interactionType: 'weblink',
              usergroupId: podStore.getUsergroupByRole('Pod').usergroupId,
              userId: session.user.userId,
              userName: podStore.userPseudonym,
              interactionId: interaction.interactionId,
              mods: {
                anchor: (editAnchor) ? JSON.parse(JSON.stringify(editAnchor)) : JSON.parse(JSON.stringify(anchor)),
                label: label,
                tModified: dayjs().unix(),
                url: validatedUrl
              }
            }
          } as Op_editWeblink)
        }
      } else {
        // transformation: add weblink, delete annotation
        const transformationAnchor = {
          nodeId: anchor.nodeId,
          relText: anchor.relText,
          rects: JSON.parse(JSON.stringify(anchor.rects)),
          tool: anchor.tool
        }
        opStore.doOp({
          op: "addWeblink",
          podId: podId,
          data: {
            interactionType: 'weblink',
            usergroupId: podStore.getUsergroupByRole('Pod').usergroupId,
            interactionId: sessionStore.createUuid(),
            userId: session.user.userId,
            userName: podStore.userPseudonym,
            anchor: editAnchor ? JSON.parse(JSON.stringify(editAnchor)) : JSON.parse(JSON.stringify(transformationAnchor)),
            style: { color: null },
            label: label,
            reactions: {},
            tCreated: dayjs().unix(),
            tModified: dayjs().unix(),
            url: validatedUrl,
            coid: null,
            tSeen: dayjs().unix(),
            dSeen: 1
          }
        } as Op_addWeblink)
        // delete annotation
        opStore.doOp({
          op: 'deleteAnnotation',
          podId,
          data: {
            userId: sessionStore.session.user.userId,
            userName: podStore.userPseudonym as string,
            usergroupId: podStore.getUsergroupByRole('Private').usergroupId,
            interactionId: interaction.interactionId
          }
        } as Op_deleteAnnotation)
      }
      handleClose()
    }
  }

  function handleClose() {
    // close modal
    uiStore.closeInteractionModal()
    // delete selected anchor in store
    uiStore.setSelectedAnchor(null)
    // remove edited text selection
    if(interaction) podStore.deleteInteractionEditAnchor(interaction.interactionId)
  }

  return (
    <Box sx={{display: "grid", gridTemplateRows: "max-content max-content auto", alignItems: "end"}}>
      <Box  sx={{ display: "grid", margin: "5px 10px", maxWidth: "600px", justifySelf: "center", width: "100%"}}>
        <TextField
          sx={{backgroundColor:"white"}}
          minRows={3}
          maxRows={3}
          multiline
          onChange={(e: ChangeEvent<HTMLInputElement>) => setLabel(e.target.value)}
          label={t("Description")}
          value={label}
          variant="outlined"
        />
      </Box>
      <Box  sx={{ display: "grid", margin: "15px 10px 5px 10px", maxWidth: "600px", justifySelf: "center", width: "100%"}}>
        <TextField
          sx={{backgroundColor:"#dedefd"}}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setUrl(e.target.value)}
          label={t("weblink")}
          value={url}
          variant="outlined"
          onKeyDown={(ev) => {
            if(ev.key === 'Enter') {
              handleSave()
              ev.preventDefault()
            }
          }}
        />
        {!isValidUrl &&
        <Alert sx={{justifyContent: "center", marginTop: "3px"}} severity="warning">{t('Url is not valid')}</Alert>
        }
      </Box>
      <Box sx={{ maxWidth: "600px", justifySelf: "center", width: "100%", marginRight: "-20px" }}>
        <SaveCancel handleSave={handleSave} handleClose={handleClose} />
      </Box>
    </Box>
  )
}

export default InteractionModalWeblink