import { t } from "i18next"
import { useStore } from "../../contexts/store"
import { UserInfo } from "../../../../types/User"
import { Avatar} from "@mui/material"
import { observer } from "mobx-react-lite"
import { env } from '../../api/api'

const UserInfoAvatar = ({userId, podId, sx, userInfoObject}: {userId: number | null | undefined, podId: string | undefined | null, sx?:object|undefined, userInfoObject?: UserInfo|undefined|null}) => {
  const { podStore } = useStore()
  // get user object
  let userInfo: UserInfo | null = null
  if(userInfoObject) {
    userInfo = userInfoObject
  } else {
    userInfo = (userId) ? podStore.getUserInfo(userId, podId) : null
  }


  // if there is no user information, return anonymous avatar
  if(userInfo === null || userInfo.userName === t('unknown')) return <Avatar sx={{...sx, borderRadius: "5px"}} />
  // remove unicodes: https://stackoverflow.com/questions/10992921/how-to-remove-emoji-code-using-javascript
  const userName = userInfo.userName.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '')

  const avatarStyle = {
    ...sx,
    borderRadius: "5px",
    backgroundColor: userInfo.color || '#f4f4f4',
  }
  // create initials for user names
  const userInitials = (userName.includes(' ') && userName.split(' ')[1].length) ? `${userName.split(' ')[0][0]}${userName.split(' ')[1][0]}`
                    :( (userName.includes('_') && userName.split('_')[1].length) ? `${userName.split('_')[0][0]}${userName.split('_')[1][0]}` : userInfo.userName[0])


  return (
    <Avatar sx={avatarStyle} title={`${userName}`} src={userInfo.avatarFileId ? `${env.backendBaseUrl}/file/${userInfo.avatarFileId}` : ''}>
      {userInitials}
    </Avatar>
  )

}

export default observer(UserInfoAvatar)